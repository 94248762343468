import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BeadConfetti } from './beadConfetti';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='/beadyLogoPinkBg.png' className="App-logo" alt="logo" />
        <BeadConfetti />
      </header>
    </div>
  );
}

export default App;
