import React, { useState } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { Button } from 'react-bootstrap'

//const drawHeartBead = (ctx) => {
//  const radius = 10; // Adjust size as needed
//  const heartSize = 6; // Adjust heart size to be smaller

  // Draw the white circle
//  ctx.fillStyle = 'white';
//  ctx.beginPath();
//  ctx.arc(0, 0, radius, 0, 2 * Math.PI);
//  ctx.fill();

  // Draw the heart
//  ctx.fillStyle = 'red';
//  ctx.beginPath();
//  ctx.moveTo(0, -heartSize);
//  ctx.bezierCurveTo(heartSize, -heartSize * 1.5, heartSize * 1.5, heartSize / 2, 0, heartSize);
//  ctx.bezierCurveTo(-heartSize * 1.5, heartSize / 2, -heartSize, -heartSize * 1.5, 0, -heartSize);
//  ctx.fill();
//};

const drawBead = (ctx) => {
  const x = 0;
  const y = 0;
  const outerRadius = 10; // Adjust size as needed
  const innerRadius = 3; // Adjust size as needed
  ctx.beginPath();
  ctx.arc(x, y, outerRadius, 0, 2 * Math.PI);
  ctx.moveTo(x + innerRadius, y);
  ctx.arc(x, y, innerRadius, 0, 2 * Math.PI, true);
  //ctx.fillStyle = ctx.fillStyle;
  ctx.fill();
};

const drawLetter = (ctx) => {
  const radius = 10; // Adjust size as needed
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const randomLetter = letters[Math.floor(Math.random() * letters.length)];

  // Draw the white circle
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.arc(0, 0, radius, 0, 2 * Math.PI);
  ctx.fill();

  // Draw the letter
  ctx.fillStyle = 'black';
  ctx.font = 'bold 12px Arial';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(randomLetter, 0, 0);
};

//const drawShape = (ctx) => {
//  if (Math.random() > 0.35) {
//    drawBead(ctx);
//  } else {
//    drawLetter(ctx);
//  }
//};

export const BeadConfetti = () => {
  const [showConfetti, setShowConfetti] = useState(false);

  const handleButtonClick = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000); // Stop confetti after 5 seconds
  };

  const { width, height } = useWindowSize()
  return (
    <>
    <Button variant="outline-dark" onClick={handleButtonClick} disabled={showConfetti}>{showConfetti ? 'We\'re Beading!' : 'Let\'s Get Beading'}</Button>
    {showConfetti && (
      <Confetti
      recycle={false}
      numberOfPieces={200}
          gravity={0.3}
      width={width}
      height={height}
      drawShape={Math.random() > 0.5 ? drawBead : drawLetter}
    />)}
    </>
  )
};